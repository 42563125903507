import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import { ISoloWeapon } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { SoloWeapon } from '../../modules/solo/common/components/solo-weapon';

interface ISoloWeaponNodes {
  nodes: ISoloWeapon[];
}

interface ISoloWeaponEntry {
  allCharacters: ISoloWeaponNodes;
}

interface IProps {
  data: ISoloWeaponEntry;
}

const SoloWeaponsPage: React.FC<IProps> = ({ data }) => {
  const [visibleWeapons, setVisibleWeapons] = useState(
    data.allCharacters.nodes
  );
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search weapons...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'R' },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Light',
            tooltip: 'Light',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_light.webp"
                width={24}
                alt="Light"
              />
            )
          },
          {
            value: 'Dark',
            tooltip: 'Dark',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_darkness.webp"
                width={24}
                alt="Dark"
              />
            )
          },
          {
            value: 'None',
            tooltip: 'None',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_none.webp"
                width={24}
                alt="None"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'dropdown',
        values: [
          { label: 'Any type', value: 'all' },
          {
            label: 'Sung Jinwoo',
            value: 'Sung'
          },
          {
            label: 'Hunter',
            value: 'Hunter'
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.rarity < b.rarity ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleWeapons(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page solo'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapons List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/solo/categories/category_weapons.jpg"
            alt="Weapons"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Weapons List</h1>
          <h2>
            All Weapons you can obtain in Solo Leveling: Arise. Their passive,
            stats and more!
          </h2>
          <p>
            Last updated: <strong>29/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Weapons"
        subtitle={
          <span className="additional-text">
            Showing {visibleWeapons.length} Weapon
            {visibleWeapons.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar solo">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Row xs={1} xxl={2} className="solo-weapons-container">
        {visibleWeapons.map((emp, index) => {
          return (
            <Col key={index}>
              <SoloWeapon name={emp.name} mode="card" showLabel />
            </Col>
          );
        })}
      </Row>
      {visibleWeapons.length === 0 && (
        <div className="no-results">
          <StaticImage src="../../images/solo/sad.webp" alt="No results" />
          <p>No weapons found. Try changing your filter. </p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default SoloWeaponsPage;

export const Head: React.FC = () => (
  <Seo
    title="Weapons | Solo Leveling: Arise | Prydwen Institute"
    description="All Weapons you can obtain in Solo Leveling: Arise. Their info, stats and more!"
    game="solo"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulSoloWeapon(
      sort: { fields: rarity, order: DESC }
    ) {
      nodes {
        id
        unitId
        name
        type
        rarity
        element
      }
    }
  }
`;
